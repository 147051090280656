import {
  DollarOutlined,
  DropboxOutlined,
  LockFilled,
  PaperClipOutlined,
  UndoOutlined,
  HeartTwoTone,
  LogoutOutlined
} from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Menu } from "antd";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import api from "../../services/api";
import { Dashboard } from "../icons/Dashboard";
import { Profile } from "../icons/Profile";
import { useNavigate } from "react-router-dom";
import { useAccessFetchRequest } from "../../Hooks/access/useAccess.query";
import { colors } from "../../Themes";
import { useAuth } from "../../services/authProvider";

function Sidenav(props) {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { color } = props;
  let { pathname } = useLocation();
  const page = pathname.split("/");
  pathname = pathname.replace("/", "");

  const ACCESS_GN_SENIOR = 60;
  const { data, isLoading: loading, status } = useAccessFetchRequest();
  const access = data?.data.access;

  const MenuItems = [
    {
      title: "Dashboard",
      link: "/",
      icon: <Dashboard color={colors.palette.primaryDark} />,
      display: access == ACCESS_GN_SENIOR
    },
    {
      title: "Gerentes",
      link: "gerentes",
      sublink: "gerente",
      icon: <Profile color={colors.palette.primaryDark} />,
      display: access == ACCESS_GN_SENIOR
    },
    {
      title: "Investidores",
      link: "investidores",
      sublink: "investidor",
      icon: <Profile color={colors.palette.primaryDark} />,
      display: access == ACCESS_GN_SENIOR
    },
    {
      title: (
        <Link
          onClick={() => {
            logout();
          }}
        >
          Sair
        </Link>
      ),
      link: "sair",
      icon: <LogoutOutlined />,
      display: true
    }
  ];

  let activePage = null;
  if (page.length === 1) {
    activePage = page[0];
  }
  if (page.length > 1) {
    activePage = page[1];
  }

  return (
    <>
      <div className="brand" style={{ textAlign: "center" }}>
        <img src={logo} alt="" style={{ height: "60px" }} />
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        {MenuItems.map((menu, index) => {
          return (
            menu.display && (
              <Menu.Item key={index}>
                <NavLink to={`${menu.link}`}>
                  <span
                    className="icon"
                    style={{
                      background:
                        activePage === `${menu.link}` ||
                        activePage === `${menu.sublink}`
                          ? color
                          : ""
                    }}
                  >
                    {menu.icon}
                  </span>
                  <span className="label">{menu.title}</span>
                </NavLink>
              </Menu.Item>
            )
          );
        })}
      </Menu>
    </>
  );
}

export default Sidenav;
