import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Progress, Row, notification } from "antd";
import { IKContext, IKUpload } from "imagekitio-react";
import React, { useRef, useState } from "react";
import Lottie from "react-lottie";
import { useUploadProfilePicture } from "../../Hooks/profile/useProfile.query";
import animationDataError from "../Lotties/error";
import animationDataSuccess from "../Lotties/success";
import "./styles.css";

const ProfilePicture = ({ id, title }) => {
  const reftest = useRef(null);
  const [api, contextHolder] = notification.useNotification();
  const queryClient = useQueryClient();
  const [progress, setProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  // IMAGEKIT UPLOAD
  const publicKey = "public_Hqt+AgHm0gjcWTX7lFzrSP0QwhE=";
  const urlEndpoint = "https://ik.imagekit.io/qqrtx9mgqo/";
  const authenticationEndpoint = `${process.env.REACT_APP_API}/v1/site/auth`;
  const folder = `${id}/document/${btoa(title)}`;

  const { mutateAsync: uploadDocument } = useMutation(useUploadProfilePicture, {
    onSettled: () => {
      queryClient.invalidateQueries("user-logged");
    },
    onSuccess: () => {
      queryClient.invalidateQueries("user-logged");
      // Toast.show({
      //   icon: "success",
      //   content: "",
      // });
    }
  });

  const onError = (err) => {
    console.log("error: ", err);
    setUploadError(true);
    api.error({
      message: "Error",
      description: err.message
    });
  };

  const onSuccess = (res) => {
    const imageUrl = res.url;
    const imageThumbnailUrl = res.thumbnailUrl;
    const imageName = res.name;
    const imageFilePath = res.filePath;
    const dataDocument = {
      imageUrl,
      imageThumbnailUrl,
      imageName,
      imageFilePath,
      id
    };
    // toast.success('Imagem enviada com sucesso', {
    //   position: toast.POSITION.TOP_CENTER
    // });
    setUploadSuccess(true);
    uploadDocument(dataDocument);
  };

  const onUploadStart = (evt) => {};

  const onUploadProgress = (event) => {
    const percent = Math.floor((event.loaded / event.total) * 100);
    setProgress(percent);
    setUploadError(false);
    setUploadSuccess(false);
    if (percent === 100) {
      setProgress(0);
    }
  };

  return (
    <>
      <Row>
        {contextHolder}
        <div className="file-upload">
          <IKContext
            publicKey={publicKey}
            urlEndpoint={urlEndpoint}
            authenticationEndpoint={authenticationEndpoint}
          >
            <IKUpload
              fileName={`${title}`}
              onError={onError}
              onSuccess={onSuccess}
              useUniqueFileName={false}
              folder={"/profile-picture"}
              onUploadStart={onUploadStart}
              onUploadProgress={onUploadProgress}
              className="upload"
              style={{ display: "none" }}
              inputRef={reftest}
            />
          </IKContext>
          {reftest && (
            <button
              className="button-custom-upload"
              onClick={() => reftest.current.click()}
            >
              Alterar Foto
            </button>
          )}
        </div>
      </Row>
      <Row>
        {progress > 0 ? (
          <>
            {progress}
            <Progress
              style={{ marginTop: "20px" }}
              type="linear"
              percent={progress}
              size={20}
            />
          </>
        ) : null}

        {uploadError && (
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: animationDataError,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
            height={50}
            width={50}
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              top: "50px"
            }}
          />
        )}
        {uploadSuccess && (
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: animationDataSuccess,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
            height={50}
            width={50}
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              top: "50px"
            }}
          />
        )}
      </Row>
    </>
  );
};

export default ProfilePicture;
