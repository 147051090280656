import {
  Typography,
  Avatar,
  Card,
  Col,
  List,
  Popover,
  Row,
  Spin,
  Statistic,
  Table,
  Space,
  Tag
} from "antd";
import React from "react";
import {
  useWalletAmountBalanceFetchRequest,
  useWalletBalanceRequest,
  useWalletExpenseBalanceFetchRequest
} from "../../Hooks/wallet/useWallet.query";
import { useInvestorsFetchRequest } from "../../Hooks/investor/useInvestor.query";
import { formatCurrency } from "../../helpers/moneyFormat";
import Title from "antd/es/typography/Title";
import { usePromotionsRequest } from "../../Hooks/promotions/usePromotions.query";
import {
  useManagersRequest,
  useRankingManagersRequest
} from "../../Hooks/manager/useManager.query";
import DailyMotivationalQuote from "../../components/Messages/dailyMotivationalQuote";
import { Link, useNavigate } from "react-router-dom";
import { ArrowUpOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { BalanceCard } from "../../components/Cards";

const Home = () => {
  const navigate = useNavigate();
  const { data: balance, isLoading: loadingBalance } =
    useWalletAmountBalanceFetchRequest();

  const { data: managers, isLoading: loadingManagers } = useManagersRequest();
  const { data: investors, isLoading: loadingInvestors } =
    useInvestorsFetchRequest();

  const { data: montlyBalance } = useWalletBalanceRequest();

  function sumMontlyAmount(balance) {
    let total = 0;
    for (var i = 0; i < balance?.length; i++) {
      total += parseFloat(balance[i].amount);
    }
    return total;
  }

  // if (isLoading) {
  //   return (
  //     <div
  //       className="layout-content"
  //       style={{
  //         width: "100%",
  //         height: "100%",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center"
  //       }}
  //     >
  //       <Spin size="large" />
  //     </div>
  //   );
  // }

  return (
    <>
      <Row gutter={16}>
        <Col span={6}>
          <BalanceCard
            title="Saldo Total"
            usd={balance?.USD}
            brl={balance?.BRL}
            extra={""}
          />
        </Col>
        <Col span={6}>
          <BalanceCard
            title="Aportes no mês"
            brl={sumMontlyAmount(montlyBalance)}
          />
        </Col>
        {/* <Col span={6}>
          <BalanceCard title="Rendimento do mês" extra={"Em breve"} />
        </Col> */}
      </Row>

      <Row gutter={16}>
        <Col span={12} className="mb-24">
          <Space>
            <Title level={4}>
              Nossos Gn{" "}
              <Tag>
                {loadingManagers ? <Spin size="small" /> : managers?.count}
              </Tag>{" "}
            </Title>
          </Space>
          <Table
            loading={loadingManagers}
            dataSource={managers?.managers?.map((manager) => {
              return {
                ...manager,
                key: manager.id
              };
            })}
            columns={[
              {
                title: "Nome",
                dataIndex: "name",
                key: "name"
              },
              {
                title: "Clientes",
                dataIndex: "clients",
                key: "clients",
                render: (clients) => {
                  return <p>{clients?.length}</p>;
                }
              },
              {
                title: "Valor na carteira",
                dataIndex: "total_amount",
                key: "total_amount",
                render: (total_amount) => {
                  return <p>{formatCurrency(total_amount, "USD")}</p>;
                }
              }
            ]}
            className="ant-border-space custom-row-hover"
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  navigate(`/gerente/${record?.id}/carteira`);
                }
              };
            }}
          />
        </Col>
        <Col span={12} className="mb-24">
          <Space>
            <Title level={4}>
              Nossos investidores <Tag>{investors?.count}</Tag>{" "}
            </Title>
          </Space>
          <Table
            dataSource={investors?.clients?.map((investor) => {
              return {
                ...investor,
                key: investor.id
              };
            })}
            columns={[
              {
                title: "Name",
                dataIndex: "name",
                key: "name"
              },
              {
                title: "Valor de carteira",
                dataIndex: "amount",
                key: "amount",
                render: (amount) => {
                  return <p>{formatCurrency(amount, "USD")}</p>;
                }
              }
            ]}
            className="ant-border-space custom-row-hover"
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  navigate(`/investidor/${record?.id}/informacoes`);
                }
              };
            }}
          />
        </Col>
      </Row>
    </>

    // <div className="layout-content">
    //   <Row gutter={5}>
    //     <Col span={24} className="mb-24">
    //       <Row gutter={5}>
    //         <Col
    //           xs={{ flex: "100%" }}
    //           sm={{ flex: "50%" }}
    //           md={{ flex: "50%" }}
    //           lg={{ flex: "25%" }}
    //           xl={{ flex: "25%" }}
    //           className="mb-24"
    //         >
    //           <Card
    //             loading={loadingAmount}
    //             bordered={false}
    //             size="small"
    //             className="criclebox h-full"
    //           >
    //             <Statistic
    //               title="Total de saldo em operação"
    //               value={formatCurrency(dataAmount?.data)}
    //               valueStyle={{
    //                 color: "#3f8600"
    //               }}
    //             />
    //           </Card>
    //         </Col>
    //         <Col
    //           xs={{ flex: "100%" }}
    //           sm={{ flex: "50%" }}
    //           md={{ flex: "50%" }}
    //           lg={{ flex: "25%" }}
    //           xl={{ flex: "25%" }}
    //           className="mb-24"
    //         >
    //           <Card bordered={false} size="small" className="criclebox h-full">
    //             <Statistic
    //               title="Valores ja repassados para investidores"
    //               value={formatCurrency(dataExpense?.data)}
    //               valueStyle={{
    //                 color: "#cf1322"
    //               }}
    //             />
    //           </Card>
    //         </Col>

    //       </Row>
    //     </Col>
    //   </Row>

    //   <Row gutter={5}>
    //     <Col
    //       xs={{ flex: 24, order: 2 }}
    //       sm={{ flex: 16, order: 2 }}
    //       md={{ flex: 14, order: 2 }}
    //       lg={{ flex: 16, order: 1 }}
    //       xl={{ flex: 15, order: 1 }}
    //     >
    //       <Row gutter={5}>
    //         <Col span={24} className="mb-24">
    //           <Card title="Promoções ativas">
    //             <List
    //               pagination={{
    //                 onChange: (page) => {
    //                   console.log(page);
    //                 },
    //                 pageSize: 5
    //               }}
    //               itemLayout="horizontal"
    //               dataSource={promotions}
    //               renderItem={(item, index) => (
    //                 <List.Item
    //                   extra={
    //                     <p>
    //                       {item.start_date} <small>até</small> {item.end_date}
    //                     </p>
    //                   }
    //                 >
    //                   <List.Item.Meta
    //                     avatar={
    //                       <Avatar
    //                         src={`https://api.dicebear.com/8.x/identicon/svg?seed=${index}`}
    //                       />
    //                     }
    //                     title={item.title}
    //                     description={item.description}
    //                   />
    //                 </List.Item>
    //               )}
    //             />
    //           </Card>
    //         </Col>

    //         <Col span={24} className="mb-24">
    //           <Card title="Gerentes de Negocios ativos">
    //             <List
    //               loading={loadingManagers}
    //               pagination={{
    //                 onChange: (page) => {
    //                   console.log(page);
    //                 },
    //                 pageSize: 5
    //               }}
    //               itemLayout="horizontal"
    //               dataSource={managers}
    //               renderItem={(item, index) => (
    //                 <List.Item
    //                   extra={
    //                     <Typography.Text>
    //                       Valor de carteira:{" "}
    //                       {formatCurrency(item?.walletAmount, "USD")}{" "}
    //                     </Typography.Text>
    //                   }
    //                 >
    //                   <List.Item.Meta
    //                     avatar={
    //                       <Avatar
    //                         src={`https://api.dicebear.com/8.x/fun-emoji/svg?seed=${item?.profile?.name}`}
    //                       />
    //                     }
    //                     title={
    //                       <Link
    //                         to={`/gerente/${item?.profile?.id}/informacoes`}
    //                       >
    //                         {item?.profile?.name}
    //                       </Link>
    //                     }
    //                     description={item?.office}
    //                   />
    //                 </List.Item>
    //               )}
    //             />
    //           </Card>
    //         </Col>
    //       </Row>
    //     </Col>
    //     {/* // */}
    //     <Col
    //       xs={{ flex: 24, order: 1 }}
    //       sm={{ flex: 8, order: 1 }}
    //       md={{ flex: 10, order: 1 }}
    //       lg={{ flex: 8, order: 2 }}
    //       xl={{ flex: 9, order: 2 }}
    //     >
    //       <Row gutter={10}>
    //         <Col xs={24} className="mb-24">
    //           <div
    //             style={{
    //               backgroundSize: "cover",
    //               backgroundImage:
    //                 "url(https://demos.creative-tim.com/muse-ant-design-dashboard-pro/static/media/info-card-2.9a5119d1.jpg)",
    //               height: 200,
    //               width: "100%",
    //               borderRadius: "10px"
    //             }}
    //           >
    //             <div
    //               style={{
    //                 position: "absolute",
    //                 padding: "10px"
    //               }}
    //             >
    //               <Title style={{ color: "#efefef" }} level={4}>
    //                 Bem Vindo ✌️
    //               </Title>
    //               <DailyMotivationalQuote />
    //             </div>
    //           </div>
    //         </Col>

    //       </Row>
    //     </Col>
    //   </Row>
    // </div>
  );
};

export default Home;
