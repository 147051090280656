import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  notification
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
// import moment from "moment";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useInvestorFetchRequest } from "../../Hooks/investor/useInvestor.query";
import { useManagerFetchRequest } from "../../Hooks/manager/useManager.query";
import { usePlansFetchRequest } from "../../Hooks/plans/usePlans.query";
import Title from "antd/es/typography/Title";

// const layout = {
//   labelCol: { span: 6 },
//   wrapperCol: { span: 12 },
// }
const tailLayout = {
  wrapperCol: { offset: 6, span: 12 }
};

const { Option } = Select;

const EditUser = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [addSubPlan, setAddSubPlan] = React.useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { id } = useParams();
  const [form] = Form.useForm();
  const { data } = useInvestorFetchRequest(id);
  const investor = data?.data?.data;

  const { data: dataPlans, isLoading: isLoadingPlan } = usePlansFetchRequest();
  const plans = dataPlans?.data.data;

  const { data: dataManager } = useManagerFetchRequest(investor?.manager);
  const manager = dataManager?.data;

  const put = async (dataForm) => {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/v1/profile/update?id=${investor.id}`,
      dataForm,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );

    return response.data;
  };

  const { data: dataSelectOffice, status: statusOffice } = useQuery(
    ["offices"],
    () =>
      axios
        .get(`${process.env.REACT_APP_API}/v1/offices`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              process.env.REACT_APP_ACCESS_TOKEN
            )}`
          }
        })
        .then((res) => {
          return res.data.data;
        })
  );

  const { data: dataSelectPeople, status: statusPeople } = useQuery(
    ["people"],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_API}/v1/profile/people-by-role?role=50&expand=profile`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                process.env.REACT_APP_ACCESS_TOKEN
              )}`
            }
          }
        )
        .then((res) => {
          return res.data.data;
        })
  );
  console.log(">>", investor);

  const { isLoading, mutate } = useMutation(put, {
    onSuccess: (data) => {
      if (data.status === 404) {
        api.error({
          message: "Error",
          description: data.error
        });
        return;
      }
      form.resetFields();
      queryClient.invalidateQueries("adminInvestor");
      api.success({
        message: "Sucesso",
        description: "Dados Salvo com sucesso"
      });
      navigate(`/investidor/perfil/${id}`);
    },
    onError: (err) => {
      api.error({
        message: "Error",
        description: err.error
      });
    }
  });

  const onFinish = (user) => {
    // const newDate = moment(user.start_of_contract).format("DD/MM/YYYY");
    const newDate = user.start_of_contract;
    const data = {
      ...user,
      start_of_contract: newDate
    };
    mutate(data);
  };

  const dateFormat = "DD/MM/YYYY";
  // const selectedStartDate = moment(data?.start_of_contract, dateFormat).format(
  //   "DD/MM/YYYY"
  // );

  const handleCheckSubplan = (checked) => {
    setAddSubPlan(checked.target.checked);
    form.resetFields(["plan_message"]);
  };

  return (
    <>
      {/* <div className="layout-content">
        <Content className="p-0"> */}
      {contextHolder}
      <Row gutter={[24, 5]}>
        <Col xs={24} xl={24} md={24}>
          {/* <Card
            bordered={true}
            className="criclebox tablespace mb-24"
            title="Editar"
          > */}
          <Title level={4}>Documentos</Title>
          <Divider />
          <Row gutter={[24, 5]} className="my-25 text-center">
            <Col xs={24} xl={24} md={24}>
              <Form
                style={{ paddingLeft: "20px", paddingRight: "20px" }}
                layout="vertical"
                form={form}
                name="create-user"
                onFinish={onFinish}
                fields={[
                  {
                    name: ["name"],
                    value: investor?.name
                  },
                  {
                    name: ["phone"],
                    value: investor?.phone
                  },
                  {
                    name: ["whatsapp"],
                    value: investor?.whatsapp
                  },
                  {
                    name: ["email"],
                    value: investor?.email
                  },
                  {
                    name: ["currency"],
                    value: investor?.currency
                  },
                  {
                    name: ["manager"],
                    value: investor?.manager
                  },
                  {
                    name: ["plan"],
                    value: investor?.plan
                  },
                  {
                    name: ["plan_message"],
                    value: investor?.plan_message
                  },
                  {
                    name: "subplan",
                    value: investor?.subplan
                  },
                  {
                    name: ["id_demo"],
                    value: investor?.is_demo
                  },
                  {
                    name: ["office"],
                    value: investor?.office
                  }
                  // {
                  //   name: ['start_of_contract'],
                  //   value: investor?.start_of_contract,
                  // },
                ]}
                // initialValues={{
                //   subplan: true
                // }}
              >
                <Form.Item name="name" label="Nome">
                  <Input />
                </Form.Item>
                <Form.Item name="phone" label="Telefone">
                  <Input />
                </Form.Item>
                <Form.Item name="whatsapp" label="Whatsapp">
                  <Input />
                </Form.Item>
                <Form.Item name="is_demo" label="DEMO">
                  <Switch />
                </Form.Item>

                <Row>
                  <Col span={4}>
                    <Form.Item name="subplan" valuePropName="checked" noStyle>
                      <Checkbox
                        // checked={addSubPlan}
                        onChange={handleCheckSubplan}
                      >
                        Sub Plano
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={20}>
                    {addSubPlan ? (
                      <Form.Item name="plan_message" label="Mensagem do plano">
                        <Input />
                      </Form.Item>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>

                <Form.Item name="manager" label="G.N">
                  <Select>
                    {statusPeople === "success" &&
                      dataSelectPeople?.map((item) => (
                        <Option key={item?.id} value={item?.user_id}>
                          {item?.profile?.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item name="office" label="Escritorio">
                  <Select>
                    {statusOffice === "success" &&
                      dataSelectOffice?.map((item) => (
                        <Option key={item?.id} value={item?.id}>
                          {item?.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="currency"
                  label="Moeda"
                  rules={[
                    {
                      required: true
                    }
                  ]}
                >
                  <Select placeholder="Selecione uma opçao de moeda" allowClear>
                    <Option value="USD">Dollar</Option>
                    <Option value="BRL">Real</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="plan"
                  label="Plano"
                  rules={[{ required: true }]}
                >
                  <Select>
                    {isLoadingPlan && (
                      <Option key="loading" disabled>
                        Loading...
                      </Option>
                    )}
                    {plans?.map((item) => (
                      <Option key={item?.id} value={item?.id}>
                        {item?.plan}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {/* <Form.Item
                  label={"Data de entrada (contrato)"}
                  name={"start_of_contract"}
                >
                  <DatePicker value={dateFormat} />
                </Form.Item> */}

                <Form.Item {...tailLayout}>
                  <Button
                    loading={!!isLoading}
                    style={{ width: "100%", marginRight: 0 }}
                    type="primary"
                    htmlType="submit"
                  >
                    Salvar
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          {/* </Card> */}
        </Col>
      </Row>
      {/* </Content>
      </div> */}
    </>
  );
};

export default EditUser;
