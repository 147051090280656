import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import api from "../../services/api";

const getManager = async (id) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/profile/manager-account?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const getProfile = async (id) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/office-manager/manager-profile?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const getPortfolio = async (id) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/office-manager/manager-portfolio?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const getManagers = async () => {
  try {
    const { data } = await api.get(
      `${process.env.REACT_APP_API}/v1/offices/managers`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};
const getRankingManagers = async () => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/gains-manager/wallet`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
const getManagersGains = async () => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/gains-manager`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function useManagerFetchRequest(id) {
  return useQuery(["manager", id], async () => await getManager(id), {
    enabled: !!id,
    retry: 5,
    onSuccess: (data) => {
      return data.data.data;
    },
    onError: (error) => {
      console.log(error);
    }
  });
}

export function useManagersRequest() {
  return useQuery(["managers"], async () => await getManagers(), {
    retry: 5,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      console.log(error);
    }
  });
}

export function useRankingManagersRequest() {
  return useQuery(["ranking"], async () => await getRankingManagers(), {
    retry: 5,
    onSuccess: (data) => {
      return data.data.data;
    },
    onError: (error) => {
      console.log(error);
    }
  });
}

export function useManagersGainsRequest() {
  return useQuery(["gains-manager"], async () => await getManagersGains(), {
    retry: 5,
    onSuccess: (data) => {
      return data.data.data;
    },
    onError: (error) => {
      console.log(error);
    }
  });
}

export function useProfileFetchRequest(id) {
  return useQuery(["profile-manager"], () => getProfile(id), {
    enabled: !!id,
    onSuccess: (data) => {
      return data.data.data;
    }
  });
}

export function usePortfolioFetchRequest(id) {
  return useQuery(["portfolio-manager"], () => getPortfolio(id), {
    onSuccess: (data) => {
      return data.data.data;
    }
  });
}
