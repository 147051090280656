import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  usePortfolioFetchRequest,
  useProfileFetchRequest
} from "../../Hooks/manager/useManager.query";
import { Card, Descriptions, Divider, Flex, Table, Tag } from "antd";
import Title from "antd/es/typography/Title";
import { LinkOutlined } from "@ant-design/icons";
import { formatCurrency } from "../../helpers/moneyFormat";
import { PageLoading } from "../../components/Loading";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "Saldo Aporte",
    dataIndex: "wallet",
    key: "wallet",
    render: (_, record) => {
      return <>{formatCurrency(record?.income, record.currency)}</>;
    }
  },
  {
    title: "Saldo Atual",
    dataIndex: "wallet",
    key: "wallet",
    render: (_, record) => {
      return <>{formatCurrency(record?.amount, record.currency)}</>;
    }
  },
  {
    title: "Ação",
    dataIndex: "action",
    key: "action",
    render: (text, record) => {
      return (
        <>
          <Link to={`/investidor/${record.id}/informacoes`}>
            <LinkOutlined />
          </Link>
        </>
      );
    }
  }
];

const PortfolioManager = () => {
  const { id } = useParams();
  const { data: dataProfile } = usePortfolioFetchRequest(id);
  const portfolio = dataProfile?.data.data;
  const investors = portfolio?.clients;

  if (!portfolio) return <PageLoading />;

  return (
    <>
      <Flex justify="space-between" align="center">
        <Title level={4}>Carteira</Title>
      </Flex>

      <Flex gap="middle" justify="center" align="center">
        <Tag style={{ width: "100%" }}>
          <Title level={5} style={{ textAlign: "center" }}>
            Total carteira em Real:{" "}
            {formatCurrency(portfolio?.total?.realIncome, "BRL")}
          </Title>
        </Tag>
        <Tag style={{ width: "100%" }}>
          <Title level={5} style={{ textAlign: "center" }}>
            Total carteira em Dollar:{" "}
            {formatCurrency(portfolio?.total?.dollarIncome, "USD")}
          </Title>
        </Tag>
      </Flex>
      <Divider />

      <Divider />
      <Table dataSource={investors} columns={columns} />
    </>
  );
};

export default PortfolioManager;
