import { Button, Card, Col, Descriptions, Divider, Flex, Image } from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useProfileFetchRequest } from "../../Hooks/manager/useManager.query";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const Informations = () => {
  const { id } = useParams();
  const [hiddenDocument, setHiddenDocument] = useState(false);
  const { data: dataProfile } = useProfileFetchRequest(id);
  const profile = dataProfile?.data.data;

  const handleHiddenDocument = () => {
    setHiddenDocument(!hiddenDocument);
  };

  return (
    <>
      <Flex justify="space-between" align="center">
        <Title level={4}>Informaçoes Pessoal</Title>
      </Flex>
      <Divider />

      <Descriptions column={1} bordered labelStyle={{ width: 180 }}>
        <Descriptions.Item label="login" span={3}>
          {hiddenDocument ? (
            <>
              <EyeInvisibleOutlined
                onClick={handleHiddenDocument}
                className="mr-5"
              />{" "}
              {investor?.document}{" "}
            </>
          ) : (
            <>
              {" "}
              <EyeOutlined
                onClick={handleHiddenDocument}
                className="mr-5"
              />{" "}
              ***********{" "}
            </>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Nome">{profile?.name}</Descriptions.Item>
        <Descriptions.Item label="Email">{profile?.email}</Descriptions.Item>
        <Descriptions.Item label="Telefone">{profile?.phone}</Descriptions.Item>
        <Descriptions.Item label="Whatsapp">
          {profile?.whatsapp}
        </Descriptions.Item>
      </Descriptions>
      <Divider />

      <Descriptions column={1} bordered labelStyle={{ width: 180 }}>
        <Descriptions.Item label="Endereço">
          {profile?.address?.street}, {profile?.address?.number}
        </Descriptions.Item>
        <Descriptions.Item label="Bairro">
          {profile?.address?.neighborhood}
        </Descriptions.Item>
        <Descriptions.Item label="Cidade / Estado">
          {profile?.address?.city} - {profile?.address?.state}
        </Descriptions.Item>
        <Descriptions.Item label="Complemento">
          {profile?.address?.complement}
        </Descriptions.Item>
      </Descriptions>
      <Divider />

      <Descriptions column={1} bordered labelStyle={{ width: 180 }}>
        <Descriptions.Item label="CNH">
          {profile?.documents?.number_cnh} <br />
          <Image width={100} src={profile?.documents?.cnh} />
        </Descriptions.Item>
        <Descriptions.Item label="CPF">
          {profile?.documents?.number_cpf} <br />
          <Image width={100} src={profile?.documents?.cpf} />
        </Descriptions.Item>
        <Descriptions.Item label="RG">
          {profile?.documents?.number_rg} - {profile?.documents?.expedition_rg}{" "}
          <br />
          <Image width={100} src={profile?.documents?.rg} />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default Informations;
