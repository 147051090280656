import { Button, Descriptions, Divider } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { useInvestorFetchRequest } from "../../Hooks/investor/useInvestor.query";
import { useManagerFetchRequest } from "../../Hooks/manager/useManager.query";
import { EyeFilled } from "@ant-design/icons";

const ManagerAccount = () => {
  const { id } = useParams();

  const { data: dataInvestor, isLoading } = useInvestorFetchRequest(id);
  const investor = dataInvestor?.data?.data;

  const { data: dataManager } = useManagerFetchRequest(
    investor?.manager?.user_id
  );
  const manager = dataManager?.data;

  return (
    <>
      <Title level={4}>Gerente da conta</Title>
      <Divider />
      <Descriptions>
        <Descriptions.Item label="Nome">
          {manager?.name}{" "}
          {!manager != "" ? (
            ""
          ) : (
            <Link
              style={{ marginLeft: 10 }}
              type="link"
              to={`/gerente/${manager?.id}/informacoes`}
            >
              <EyeFilled />
            </Link>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Email">{manager?.email}</Descriptions.Item>
        <Descriptions.Item label="Telefone">{manager?.phone}</Descriptions.Item>
        <Descriptions.Item label="Whatsapp">
          {manager?.whatsapp}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default ManagerAccount;
