import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getWalletBalance = async (value) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/v1/offices/amounts`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    const result = data?.data;
    return result;
  } catch (error) {
    console.log(error.message);
  }
};

const getMonthlyBalance = async (value) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/v1/offices/monthly-amount?month=2024-11`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    const result = data?.data;
    return result;
  } catch (error) {
    console.log(error.message);
  }
};

export function useWalletAmountBalanceFetchRequest(operation) {
  return useQuery(["walletAmount"], () => getWalletBalance(operation), {
    onSuccess: (data) => {
      return data?.data;
    }
  });
}

export function useWalletExpenseBalanceFetchRequest(operation) {
  return useQuery(["walletExpense"], () => getWalletBalance(operation), {
    onSuccess: (data) => {
      return data;
    }
  });
}

export function useWalletBalanceRequest(operation) {
  return useQuery(["walletBalance"], () => getMonthlyBalance(operation), {
    onSuccess: (data) => {
      return data;
    }
  });
}
