import React from "react";
import "./balance.css";
import { formatCurrency } from "../../helpers/moneyFormat";

const BalanceCard = ({
  title,
  brl = 0,
  usd = 0,
  extra = "",
  isBlackAndWhite
}) => {
  return (
    <div className={`balance-card`}>
      <div className="balance-header">
        <div className="balance-indicator"></div>
        <span className="balance-title">{title}</span>
        <span className="balance-arrow"></span>
      </div>
      <div className="balance-amount">
        {formatCurrency(brl, "BRL")}
        <br />
        {formatCurrency(usd, "USD")}
      </div>
      <div className="balance-change">
        {extra || " "}
        <span className="balance-change-icon"></span>
      </div>
    </div>
  );
};

export default BalanceCard;
