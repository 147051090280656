import { QueryClient } from "@tanstack/react-query";
import axios from "axios";

const queryClient = new QueryClient({
  defaultOptions: {
    retry: 3,
    queries: {
      refetchOnWindowFocus: false // default: true
    }
  }
});

axios.get("/");

export default queryClient;
