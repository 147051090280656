import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useInvestorFetchRequest } from "../../Hooks/investor/useInvestor.query";
import { Button, Card, Col, Flex, Form, Input, Row } from "antd";
import Title from "antd/es/typography/Title";
import { IKContext, IKUpload } from "imagekitio-react";

// const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

const Complete = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { data: dataInvestor, isLoading } = useInvestorFetchRequest(id);
  const investor = dataInvestor?.data?.data;

  console.log(investor);
  return (
    <div className="layout-content">
      <Flex justify="space-between" align="center">
        <Title level={4}>Completar cadastro</Title>
      </Flex>

      <Card bordered={false} className="criclebox tablespace mb-24">
        <Row gutter={[24, 5]} className="my-25 text-center">
          <Col xs="20" xl={20}>
            <Form
              {...layout}
              form={form}
              name="create-user"
              // onFinish={onFinish}
            >
              <Form.Item name="password" label="Senha">
                <Input />
              </Form.Item>

              <Form.Item name="phone" label="Telefone">
                <Input />
              </Form.Item>
              <Form.Item name="whatsapp" label="WhatsApp">
                <Input />
              </Form.Item>
              <Form.Item name="marital_status" label="Estado Civil">
                <Input />
              </Form.Item>
              <Form.Item name="profession" label="Profissão">
                <Input />
              </Form.Item>
              <Form.Item name="nationality" label="Nacionalidade">
                <Input />
              </Form.Item>
              <Form.Item name="postal_code" label="CEP">
                <Input />
              </Form.Item>
              <Form.Item name="street" label="Rua">
                <Input />
              </Form.Item>
              <Form.Item name="number" label="Número">
                <Input />
              </Form.Item>
              <Form.Item name="complement" label="Complemento">
                <Input />
              </Form.Item>
              <Form.Item name="neighborhood" label="Bairro">
                <Input />
              </Form.Item>
              <Form.Item name="state" label="Estado">
                <Input />
              </Form.Item>
              <Form.Item name="city" label="Cidade">
                <Input />
              </Form.Item>
              <Form.Item name="pix" label="PIX">
                <Input />
              </Form.Item>
              <Form.Item name="number_cpf" label="Número do CPF">
                <Input />
              </Form.Item>
              <Form.Item>
                <IKContext
                // publicKey={process.env.NEXT_PUBLIC_IMAGEKIT_PUBLIC_KEY}
                // urlEndpoint={process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT}
                // authenticator={authenticator}
                >
                  <div className="mb-2">
                    <label
                      htmlFor="upload-file-example"
                      className="mb-1 text-sm font-semibold"
                    >
                      Anexar documento
                    </label>
                  </div>
                  <IKUpload
                    // className="h-10 mb-1 text-sm transition bg-transparent border-0 rounded appearance-none file:font-semibold focus:outline-none focus:ring-0 file:border-0 file:h-10 file:px-6 file:disabled:bg-zinc-400 disabled:pointer-events-none file:bg-zinc-900 file:text-white file:hover:bg-zinc-800 file:rounded file:mr-4"
                    // folder={`documentation_activation_account/${user?.id}/cnh`}
                    // fileName={`${user?.name}_cnh`}
                    // onSuccess={(file) => {
                    //   setValue("cnh", file.url);
                    // }}
                    onError={(error) => {
                      console.log(error);
                    }}
                    tags={["CPF", "documentation"]}
                    useUniqueFileName={true}
                    isPrivateFile={false}
                    // onUploadProgress={onUploadProgress}
                    // onUploadStart={() => setSpinerCnh(true)}
                    // onEnded={() => setSpinerCnh(false)}
                  />
                </IKContext>
              </Form.Item>
              <Form.Item name="number_rg" label="Número do RG">
                <Input />
              </Form.Item>
              <Form.Item name="expedition_rg" label="Data de Expedição do RG">
                <Input />
              </Form.Item>
              <Form.Item>
                <IKContext
                // publicKey={process.env.NEXT_PUBLIC_IMAGEKIT_PUBLIC_KEY}
                // urlEndpoint={process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT}
                // authenticator={authenticator}
                >
                  <div className="mb-2">
                    <label
                      htmlFor="upload-file-example"
                      className="mb-1 text-sm font-semibold"
                    >
                      Anexar documento
                    </label>
                  </div>
                  <IKUpload
                    // className="h-10 mb-1 text-sm transition bg-transparent border-0 rounded appearance-none file:font-semibold focus:outline-none focus:ring-0 file:border-0 file:h-10 file:px-6 file:disabled:bg-zinc-400 disabled:pointer-events-none file:bg-zinc-900 file:text-white file:hover:bg-zinc-800 file:rounded file:mr-4"
                    // folder={`documentation_activation_account/${user?.id}/cnh`}
                    // fileName={`${user?.name}_cnh`}
                    // onSuccess={(file) => {
                    //   setValue("cnh", file.url);
                    // }}
                    onError={(error) => {
                      console.log(error);
                    }}
                    tags={["RG", "documentation"]}
                    useUniqueFileName={true}
                    isPrivateFile={false}
                    // onUploadProgress={onUploadProgress}
                    // onUploadStart={() => setSpinerCnh(true)}
                    // onEnded={() => setSpinerCnh(false)}
                  />
                </IKContext>
              </Form.Item>
              <Form.Item name="number_cnh" label="Número da CNH">
                <Input />
              </Form.Item>
              <Form.Item>
                <IKContext
                // publicKey={process.env.NEXT_PUBLIC_IMAGEKIT_PUBLIC_KEY}
                // urlEndpoint={process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT}
                // authenticator={authenticator}
                >
                  <div className="mb-2">
                    <label
                      htmlFor="upload-file-example"
                      className="mb-1 text-sm font-semibold"
                    >
                      Anexar documento
                    </label>
                  </div>
                  <IKUpload
                    // className="h-10 mb-1 text-sm transition bg-transparent border-0 rounded appearance-none file:font-semibold focus:outline-none focus:ring-0 file:border-0 file:h-10 file:px-6 file:disabled:bg-zinc-400 disabled:pointer-events-none file:bg-zinc-900 file:text-white file:hover:bg-zinc-800 file:rounded file:mr-4"
                    // folder={`documentation_activation_account/${user?.id}/cnh`}
                    // fileName={`${user?.name}_cnh`}
                    // onSuccess={(file) => {
                    //   setValue("cnh", file.url);
                    // }}
                    onError={(error) => {
                      console.log(error);
                    }}
                    tags={["CNH", "documentation"]}
                    useUniqueFileName={true}
                    isPrivateFile={false}
                    // onUploadProgress={onUploadProgress}
                    // onUploadStart={() => setSpinerCnh(true)}
                    // onEnded={() => setSpinerCnh(false)}
                  />
                </IKContext>
              </Form.Item>
              {/* <Form.Item name="cpf" label="CPF">
                <Input />
              </Form.Item>
              <Form.Item name="cnh" label="CNH">
                <Input />
              </Form.Item> */}
              <Form.Item {...tailLayout}>
                <Button
                  // loading={!!isLoading}
                  style={{ width: "100%", marginRight: 0 }}
                  type="primary"
                  htmlType="submit"
                >
                  CRIAR
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Complete;
