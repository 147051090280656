import React, { useState } from "react";
import {
  useManagersGainsRequest,
  useManagersRequest
} from "../../Hooks/manager/useManager.query";
import {
  App,
  Button,
  Divider,
  Flex,
  Form,
  Input,
  Table,
  Typography,
  InputNumber,
  Popover
} from "antd";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { InfoCircleOutlined, LinkOutlined } from "@ant-design/icons";
import MyModal from "../../components/MyModal";
import { useMutation } from "@tanstack/react-query";
import {
  useOfficesTkn,
  useUpdateOffice
} from "../../Hooks/offices/offices.query";
import { formatCurrency } from "../../helpers/moneyFormat";
import { PageLoading } from "../../components/Loading";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 }
};

const columns = [
  {
    title: "",
    dataIndex: "action",
    key: "action",
    // render: (_, text) => (
    //   <>
    //     <Link to={`/gerente/${text?.manager.id}/carteira`}>
    //       <LinkOutlined />
    //     </Link>
    //   </>
    // ),
    width: "5%"
  },
  {
    title: "Gerente",
    dataIndex: "name",
    key: "name"
    // render: (_, text) => (
    //   <>
    //     <Typography.Text>{text?.}</Typography.Text>
    //   </>
    // )
  },
  {
    title: "Nº Clientes",
    dataIndex: "clients",
    key: "clients",
    render: (text) => (
      <>
        <Typography.Text>{text.length}</Typography.Text>
      </>
    ),
    width: "5%"
  },
  {
    title: "Valor da carteira",
    dataIndex: "total_amount",
    key: "total_amount",
    render: (text) => (
      <>
        <Typography.Text>{formatCurrency(text, "USD")}</Typography.Text>
      </>
    ),
    width: "10%"
  }
];

const ManagerGain = () => {
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data: OfficeId } = useOfficesTkn();
  const office = OfficeId?.data;

  const { data, isLoading, isError, error } = useManagersRequest();
  // const managers = data?.data?.data;

  const handleOpenModalConfig = () => {
    setIsModalVisible(true);
  };

  const handleCancelModalConfig = () => {
    setIsModalVisible(false);
  };

  const { mutate } = useMutation(useUpdateOffice);

  const onFinish = (sender) => {
    mutate({
      id: office,
      sender
    });
  };

  if (isError) {
    notification.error({
      message: "Erro ao carregar os dados",
      description: error.message
    });
  }

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <div className="layout-content">
      <Flex justify="space-between" align="center">
        <Button>
          <NavLink to="criar">Cadastrar</NavLink>
        </Button>
        {/* <Button onClick={handleOpenModalConfig}>Configuração de ganho</Button> */}
      </Flex>
      <Divider />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data?.managers?.map((manager) => {
          return {
            ...manager,
            key: manager.id
          };
        })}
        pagination={true}
        className="ant-border-space custom-row-hover"
        bordered
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/gerente/${record?.id}/carteira`);
            }
          };
        }}
      />

      <MyModal
        isVisible={isModalVisible}
        onCancel={handleCancelModalConfig}
        onOk={onFinish}
        okButtonProps={{
          hidden: true
        }}
        cancelButtonProps={{
          disabled: true
        }}
      >
        <Typography.Title level={4}>Configuração de ganho</Typography.Title>
        <Divider />
        <Typography.Paragraph>
          Informe a porcentagem de ganho do Gerente Sênior sobre o Gerente. Essa
          informação é vital para estabelecer uma remuneração justa e
          motivadora. Considere cuidadosamente os critérios como experiência e
          desempenho ao inserir o valor.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Obrigado pela contribuição para nossa cultura organizacional
          equitativa.
        </Typography.Paragraph>
        <Divider />
        <Typography.Paragraph>Atual</Typography.Paragraph>

        <Form
          {...layout}
          form={form}
          name="create-user"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="gain_manager_master"
            label="Porcentagem a ser repassado"
            tooltip="Separe o valor decimal da porcentagem usando ponto (.). EX: 1.5, 10.4"
            rules={[{ required: true }]}
          >
            <InputNumber
              style={{
                width: "100%"
              }}
              min="0"
              step="0.01"
              stringMode
              addonAfter="%"
            />
            {/* <Input addonAfter="%" type="number" /> */}
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              loading={!!isLoading}
              style={{ width: "100%", marginRight: 0 }}
              type="primary"
              htmlType="submit"
            >
              CRIAR
            </Button>
          </Form.Item>
        </Form>
      </MyModal>
    </div>
  );
};

export default ManagerGain;
